// @flow
import React from 'react'
import { Link, graphql } from 'gatsby'

import { Container, Row, Col } from 'reactstrap'
import mapAirtableData from '../lib/mapAirtableData'

import Layout, { SEO } from '../components/Layout'
import { Button } from '../components/UI'

type Props = {
  data: Object,
}

const NotFoundPage = (props: Props) => {
  const {
    data: { allAirtable },
  } = props
  const texts = mapAirtableData(allAirtable)

  return (
    <Layout>
      <SEO title="404: Seite nicht gefunden" />
      <Container>
        <Row>
          <Col sm={{ size: 8, offset: 2 }} style={{ textAlign: 'center' }}>
            <h1>{texts['404 Titel']}</h1>
            <p>{texts['404 Text']}</p>

            <Link to="/">
              <Button as="span">Zurück zur Startseite</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "Allgemeines" } }) {
      edges {
        node {
          recordId
          data {
            Name
            Text
          }
        }
      }
    }
  }
`
